import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {InventoryAllocationDivisionType} from '../../model/inventory/inventoryAllocationDivisionType';
import {Customer} from '../../model/customer/customer';

export interface AllocateCustomerInventoryDialogProps {
    error?: string;
    open: boolean;
    customers: Customer[];
    availableInventory: number;
    handleClose: () => void;
    handleSubmit: (data: any) => Promise<void>;
}

export const AllocateCustomerInventoryDialog = (props: AllocateCustomerInventoryDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;
    const [active, setActive] = useState<boolean>(true);
    const [customerId, setCustomerId] = useState<string>();
    const [inventoryTotal, setInventoryTotal] = useState<number>();
    const [inventoryTotalError, setInventoryTotalError] = useState<string>();
    const [allocationDivision, setAllocationDivision] = useState<number>(0);
    const [allocationCount, setAllocationCount] = useState<number>();
    const [completionReward, setCompletionReward] = useState<string>();
    const [completionRewardClaimTimeoutHours, setCompletionRewardClaimTimeoutHours] = useState<number>();

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Allocate Customer Inventory</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="active">Active</InputLabel>
                        <Select id="active" defaultValue={'true'} onChange={(event) => {
                            setActive(event.target.value == 'true');
                        }}>
                            <MenuItem key={'active_true'} value={'true'}>Active</MenuItem>
                            <MenuItem key={'active_false'} value={'false'}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="customer">Customer</InputLabel>
                        <Select id="customer" defaultValue={props.customers.length > 0 ? props.customers[0].id : ''} onChange={(event) => {
                            setCustomerId(props.customers[event.target.value].id);
                        }}>
                            {props.customers.map((p, i) => {
                                return <MenuItem key={p.id} value={i}>{p.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="inventoryTotal" type={'text'} defaultValue={''} onChange={(event) => {
                            const inventoryTotal = parseInt(event.target.value);

                            if (inventoryTotal > props.availableInventory) {
                                setInventoryTotalError(`Specified inventory total must be less than remaining inventory (${props.availableInventory}).`);
                            } else {
                                setInventoryTotalError('');
                                setInventoryTotal(inventoryTotal);
                            }

                        }} label='Inventory Count' />
                        <span color={'#FF0000'}>{inventoryTotalError}</span>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="allocationDivision">Allocation Division Style</InputLabel>
                        <Select id="allocationDivision" defaultValue={0} onChange={(event) => {
                            setAllocationDivision(event.target.value as number);
                        }}>
                            <MenuItem key={'division_count'} value={0}>Number of Allocations</MenuItem>
                            <MenuItem key={'division_size'} value={1}>Size of Allocations</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="allocationCount" type={'text'} defaultValue={''} onChange={(event) => {
                            setAllocationCount(parseInt(event.target.value));
                        }} label='Allocation Count' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="completionReward" type={'text'} defaultValue={''} onChange={(event) => {
                            setCompletionReward(event.target.value);
                        }} label='Completion Reward' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="completionRewardClaimTimeout" type={'text'} defaultValue={''} onChange={(event) => {
                            setCompletionRewardClaimTimeoutHours(parseInt(event.target.value));
                        }} label='Completion Reward Claim Timeout (Hours)' />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleSubmit({active, customerId, inventoryTotal, allocationDivision, allocationCount, completionReward, completionRewardClaimTimeoutHours});
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
