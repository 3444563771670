import {IHttpClient} from 'src/core/http';
import {ServiceResponse} from '../../model/serviceResponse';
import {User} from '../../model/user/user';
import {UserResponse} from '../../model/user/userResponse';
import {ImpactType} from '../../model/impact/impactType';
import {MatchmakerHistory} from '../../model/inventory/matchmakerHistory';
import {Partner} from '../../model/partner/partner';
import {InventoryItemDefinition} from '../../model/inventory/inventoryItemDefinition';

export interface IInventoryService {
    requestInventory(userId: string, customerId: string, type: ImpactType, quantity: number): Promise<ServiceResponse<string>>;

    listInventoryItemDefinitions(): Promise<ServiceResponse<InventoryItemDefinition[]>>;
    addInventoryItemDefinition(inventoryItemDefinitionId: any): Promise<ServiceResponse<string>>;
    getInventoryItemDefinition(inventoryItemDefinitionId: string): Promise<ServiceResponse<InventoryItemDefinition>>;
    deleteInventoryItemDefinition(inventoryItemDefinitionId: string): Promise<ServiceResponse<boolean>>;
    deleteAllInventoryItems(userId: string, customerId: string): Promise<ServiceResponse<boolean>>;

    matchmake(): Promise<ServiceResponse<boolean>>;
    getMatchmakerHistory(): Promise<ServiceResponse<MatchmakerHistory[]>>;
}

export class InventoryService implements IInventoryService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async requestInventory(userId: string, customerId: string, type: ImpactType, quantity: number): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/inventory/request`, {userId, customerId, type, quantity})).data;
    }

    public async matchmake(): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.get<boolean>(`${this.apiUrl}/inventory/matchmake`)).data;
    }

    public async getMatchmakerHistory(): Promise<ServiceResponse<MatchmakerHistory[]>> {
        return (await this.httpClient.get<MatchmakerHistory[]>(`${this.apiUrl}/inventory/matchmakerHistory`)).data;
    }

    public async listInventoryItemDefinitions(): Promise<ServiceResponse<InventoryItemDefinition[]>> {
        return (await this.httpClient.get<InventoryItemDefinition[]>(`${this.apiUrl}/inventory/itemDefinition`, {})).data;
    }

    public async addInventoryItemDefinition(inventoryItemDefinitionData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/inventory/itemDefinition`, inventoryItemDefinitionData)).data;
    }

    public async getInventoryItemDefinition(inventoryItemDefinitionId: string): Promise<ServiceResponse<InventoryItemDefinition>> {
        return (await this.httpClient.get<InventoryItemDefinition>(`${this.apiUrl}/inventory/itemDefinition/${inventoryItemDefinitionId}`)).data;
    }

    public async deleteInventoryItemDefinition(inventoryItemDefinitionId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/inventory/itemDefinition/${inventoryItemDefinitionId}`)).data;
    }

    public async deleteAllInventoryItems(userId: string, customerId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/inventory/${userId}/${customerId}`)).data;
    }
}
