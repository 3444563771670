import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {HttpHeader, IHttpResponse} from './types';
import {ServiceResponse, ServiceResponseServerModel} from '../../model/serviceResponse';

export interface IHttpClient {
    addHeader(key: string, value: string | number);
    removeHeader(key: string);
    get<T>(url: string, params?: {}, options?: {}): Promise<IHttpResponse<T>>
    put<T>(url: string, data: {}, options?: {}): Promise<IHttpResponse<T>>
    post<T>(url: string, data: {}, options?: {}): Promise<IHttpResponse<T>>
    delete<T>(url: string, params?: {}, options?: {}): Promise<IHttpResponse<T>>
    download(url: string, fileName: string, params?: {}, options?: {}): Promise<void>;
}

export class HttpClient implements IHttpClient {
    public static readonly AUTHORIZATION_HEADER = 'Authorization';
    private headers: HttpHeader;

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    public addHeader(key: string, value: string | number): void {
        this.headers[key] = value;
    }

    public removeHeader(key: string): void {
        delete this.headers[key];
    }

    public async get<T>(url: string, params?: {}, options?: {}): Promise<IHttpResponse<T>> {
        const response = await axios.get(url, {headers: this.headers, params, ...options} as AxiosRequestConfig);
        return this.handleResponse<T>(response);
    }

    public async put<T>(url: string, data: {}, options?: {}): Promise<IHttpResponse<T>> {
        const response = await axios.put(url, data, {headers: this.headers, ...options} as AxiosRequestConfig);
        return this.handleResponse<T>(response);
    }

    public async post<T>(url: string, data: {}, options?: {}): Promise<IHttpResponse<T>> {
        const response = await axios.post(url, data, {headers: this.headers, ...options} as AxiosRequestConfig);
        return this.handleResponse<T>(response);
    }

    public async delete<T>(url: string, params?: {}, options?: {}): Promise<IHttpResponse<T>> {
        const response = await axios.delete(url, {headers: this.headers, params, ...options} as AxiosRequestConfig);
        return this.handleResponse<T>(response);
    }

    public async download(url: string, fileName: string, params?: {}, options?: {}): Promise<void> {
        const response = await axios.get(url, {headers: this.headers, params, responseType: 'blob', ...options} as AxiosRequestConfig);
        const temp = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = temp;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    private handleResponse<T>(response: AxiosResponse<any, any>): IHttpResponse<T> {
        return {
            hasError: response.status >= 400,
            data: ServiceResponse.fromServerResponse<T>(response.data as ServiceResponseServerModel)
        };
    }
}
