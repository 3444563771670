import {createContext} from 'react';

export enum Service {
    AuthService,
    HttpClient,
    Partner,
    Customer,
    User,
    Impact,
    Project,
    Inventory
}

export interface IServiceLocator {
    get<T>(key: Service): T | null;
    register<T>(key: Service, instance: T): void;
}
export class ServiceLocator implements IServiceLocator {

    private _instances: Map<any, any>;

    constructor() {
        this._instances = new Map<any, any>();
    }
    public get<T>(key: Service): T | null {
        return this._instances.get(key) as T;
    }
    public register<T>(key: Service, instance: T): void {
        this._instances.set(key, instance);
    }
}

export const ServiceContext = createContext(new ServiceLocator());
