import React, {useState} from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Link} from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmAlert} from 'react-confirm-alert';
import {ImpactType} from '../../model/impact/impactType';
import {IInventoryService} from '../../service/inventory/inventoryService';
import {AddInventoryItemDefinitionDialog} from './addInventoryItemDefinitionDialog';
import {InventoryItemDefinition} from '../../model/inventory/inventoryItemDefinition';

export interface InventoryItemDefinitionsProps {
    definitions: ServiceResponse<InventoryItemDefinition[]>;
}

export const InventoryItemDefinitionsComponent = (props: InventoryItemDefinitionsProps) => {
    const [openAddInventoryItemDefinitionDialog, setOpenAddInventoryItemDefinitionDialog] = useState<boolean>(false);
    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const [addInventoryItemDefinitionError, setAddInventoryItemDefinitionError] = useState<string>();

    return (
        <div>
            <h1>InventoryItemDefinitions</h1>
            <h2>Admin</h2>
            <AddInventoryItemDefinitionDialog
                open={openAddInventoryItemDefinitionDialog}
                handleClose={() => {
                    setOpenAddInventoryItemDefinitionDialog(false);
                }}
                error={addInventoryItemDefinitionError}
                handleSubmit={async (data) => {
                    const result = await inventoryService?.addInventoryItemDefinition(data);

                    if (result?.hasError()) {
                        setAddInventoryItemDefinitionError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <Button variant={'contained'} onClick={() => {
                setOpenAddInventoryItemDefinitionDialog(true);
            }}>Add Inventory Item Definition</Button>

            <h2>Registered InventoryItemDefinitions</h2>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.definitions.response.map((definition, index) => {
                            return <TableRow
                                key={definition.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">{definition.id}</TableCell>
                                <TableCell align="right">{ImpactType[definition.type]}</TableCell>
                                <TableCell align="right">{definition.name}</TableCell>
                                <TableCell align="right">
                                    <Button variant={'contained'}><Link to={`/admin/inventory/itemDefinition/${definition.id}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>View</Link></Button>
                                    <Button variant={'contained'} color={'error'} onClick={() => {
                                        confirmAlert({
                                            title: 'Delete Inventory Item Definition',
                                            message: `Are you sure you want to delete Inventory Item Definition ${definition.id}?`,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: async () => {
                                                        await inventoryService?.deleteInventoryItemDefinition(definition.id ?? '');
                                                        window.location.reload();
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onClick: () => {}
                                                }
                                            ]
                                        });
                                    }}>Delete</Button>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
