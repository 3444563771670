import React, {useState} from 'react';
import {AddPartnerDialog} from './addPartnerDialog';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Partner} from '../../model/partner/partner';
import {Link} from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmAlert} from 'react-confirm-alert';
import {IPartnerService} from '../../service/partner/partnerService';
import {ImpactType} from '../../model/impact/impactType';

export interface PartnersComponentProps {
    partners: ServiceResponse<Partner[]>;
}

export const PartnersComponent = (props: PartnersComponentProps) => {
    const [openAddPartnerDialog, setOpenAddPartnerDialog] = useState<boolean>(false);
    const [partnerService] = useService<IPartnerService>(Service.Partner);
    const [addPartnerError, setAddPartnerError] = useState<string>();

    return (
        <div>
            <h1>Partners</h1>
            <h2>Admin</h2>
            <AddPartnerDialog
                open={openAddPartnerDialog}
                handleClose={() => {
                    setOpenAddPartnerDialog(false);
                }}
                error={addPartnerError}
                handleSubmit={async (data) => {
                    const result = await partnerService?.addPartner(data);

                    if (result?.hasError()) {
                        setAddPartnerError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <Button variant={'contained'} onClick={() => {
                setOpenAddPartnerDialog(true);
            }}>Add Partner</Button>

            <h2>Registered Partners</h2>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.partners.response.map((partner, index) => {
                            return <TableRow
                                key={partner.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">{partner.id}</TableCell>
                                <TableCell align="right">{partner.name}</TableCell>
                                <TableCell align="right">{ImpactType[partner.type]}</TableCell>
                                <TableCell align="right">
                                    <Button variant={'contained'}><Link to={`/admin/partner/${partner.id}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>View</Link></Button>
                                    <Button variant={'contained'} color={'error'} onClick={() => {
                                        confirmAlert({
                                            title: 'Delete Partner',
                                            message: `Are you sure you want to delete partner ${partner.id}?`,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: async () => {
                                                        await partnerService?.deletePartner(partner.id ?? '');
                                                        window.location.reload();
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onClick: () => {}
                                                }
                                            ]
                                        });
                                    }}>Delete</Button>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
