import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

export const localTheme = createTheme({
    palette: {
        secondary: {
            main: '#FFDA33',
        },
        error: {
            main: red.A400,
        },
    },
});


export const devTheme = createTheme({
    palette: {
        secondary: {
            main: '#33D1FF',
        },
        error: {
            main: red.A400,
        },
    },
});

export const prodTheme = createTheme({
    palette: {
        secondary: {
            main: '#FF33F6',
        },
        error: {
            main: red.A400,
        },
    },
});

