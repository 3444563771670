import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {Service} from '../../service/serviceLocator';
import {IPartnerService} from '../../service/partner/partnerService';
import {PartnersComponent} from '../../component/partner/partnersComponent';

interface PartnersProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    return {
        props: {
            env: ctx.req.env
        }
    };
};

const PartnersPage = (props: PartnersProps) => {
    document.title = `Partners - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [partnerService] = useService<IPartnerService>(Service.Partner);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [partnerList, setPartnerList] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await partnerService?.listPartners();
            setPartnerList(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [partnerService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <PartnersComponent partners={partnerList} />
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default PartnersPage;
