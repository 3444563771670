import {styled, Typography} from '@mui/material';
import {useState} from 'react';
import {AppBar} from './appBar/appBar';
import {ILink, NavigationDrawer} from './navigation/navigationDrawer';
import {Link} from 'react-router-dom';
import * as React from 'react';

export interface ILayoutProps {
    env: string;
    version: string;
    children?:
        | React.ReactChild
        | React.ReactChild[];
}

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

export const Layout = (props: ILayoutProps) => {
    const links: ILink[] = [
        {title: 'Partners', link: '/admin/partners'},
        {title: 'Customers', link: '/admin/customers'},
        {title: 'Users', link: '/admin/users/search'},
        {title: 'Projects', link: '/admin/projects'},
        {title: 'Inventory Item Definitions', link: '/admin/inventory/itemDefinitions'},
        {title: 'Impact', link: '/admin/impact'},
        {title: 'Matchmaker', link: '/admin/inventory/matchmaker'}
    ];

    const [open, setOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <div key={'Layout'}>
            <AppBar open={open} env={props.env} version={props.version} handleDrawerOpen={handleDrawerOpen}/>
            <NavigationDrawer setOpen={setOpen} isOpened={open} links={links}/>
            <Main open={open}>
                {props.children}
            </Main>
        </div>
    );
};
