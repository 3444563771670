import React from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {RouterProvider} from 'react-router-dom';
import {devTheme, localTheme, prodTheme} from './layout/theme';
import {AppRouter, PrivateRoute} from './core/router';
import Index from './page';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Service, ServiceContext, ServiceLocator} from './service/serviceLocator';
import {AuthService} from './service/auth/authService';
import {HttpClient, IHttpClient,} from './core/http';
import {AuthorizedHttpClient} from './core/http/authorizedHttpClient';
import {IImpactService, ImpactService} from './service/impact/impactService';
import {IPartnerService, PartnerService} from './service/partner/partnerService';
import PartnerPage from './page/partner/partnerPage';
import PartnersPage from './page/partner/partnersPage';
import ImpactPage from './page/impact/impactPage';
import CustomersPage from './page/customer/customersPage';
import CustomerPage from './page/customer/customerPage';
import {CustomerService, ICustomerService} from './service/customer/customerService';
import {IUserService, UserService} from './service/user/userService';
import UserPage from './page/user/userPage';
import UserSearchPage from './page/user/userSearchPage';
import {IProjectService, ProjectService} from './service/project/projectService';
import ProjectPage from './page/project/projectPage';
import ProjectsPage from './page/project/projectsPage';
import {IInventoryService, InventoryService} from './service/inventory/inventoryService';
import MatchmakerPage from './page/inventory/matchmakerPage';
import InventoryItemDefinitionsPage from './page/inventory/inventoryItemDefinitionsPage';
import InventoryItemDefinitionPage from './page/inventory/inventoryItemDefinitionPage';

function App() {
    const appRouter = new AppRouter();

    const unauthorizedHttpClient: IHttpClient = new HttpClient();
    const authService = new AuthService(process.env.REACT_APP_API_URL || '', window.localStorage, unauthorizedHttpClient);
    const authorizedHttpClient: IHttpClient = new AuthorizedHttpClient(unauthorizedHttpClient, authService);
    const partnerService: IPartnerService = new PartnerService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const customerService: ICustomerService = new CustomerService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const userService: IUserService = new UserService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const impactService: IImpactService = new ImpactService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const projectService: IProjectService = new ProjectService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const inventoryService: IInventoryService = new InventoryService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);

    authService.initialize();
    const env = process.env.REACT_APP_ENV || 'local';
    const version = process.env.REACT_APP_VERSION;

    const router = appRouter.registerRoutes([
        PrivateRoute('/', <Index env={env} />),
        PrivateRoute('/admin', <Index env={env} />),
        PrivateRoute('/admin/partners', <PartnersPage env={env} />),
        PrivateRoute('/admin/partner/:partnerId', <PartnerPage env={env} />),
        PrivateRoute('/admin/customers', <CustomersPage env={env} />),
        PrivateRoute('/admin/customer/:customerId', <CustomerPage env={env} />),
        PrivateRoute('/admin/impact', <ImpactPage env={env} />),
        PrivateRoute('/admin/users/search', <UserSearchPage env={env} />),
        PrivateRoute('/admin/user/:userId', <UserPage env={env} />),
        PrivateRoute('/admin/projects', <ProjectsPage env={env} />),
        PrivateRoute('/admin/project/:projectId', <ProjectPage env={env} />),
        PrivateRoute('/admin/inventory/matchmaker', <MatchmakerPage env={env} />),
        PrivateRoute('/admin/inventory/itemDefinitions', <InventoryItemDefinitionsPage env={env} />),
        PrivateRoute('/admin/inventory/itemDefinition/:inventoryItemDefinitionId', <InventoryItemDefinitionPage env={env} />)
    ]);

    const serviceLocator = new ServiceLocator();

    // to make it testable, url should be injected.
    serviceLocator.register(Service.HttpClient, authorizedHttpClient);
    serviceLocator.register(Service.AuthService, authService);
    serviceLocator.register(Service.Partner, partnerService);
    serviceLocator.register(Service.Customer, customerService);
    serviceLocator.register(Service.User, userService);
    serviceLocator.register(Service.Impact, impactService);
    serviceLocator.register(Service.Project, projectService);
    serviceLocator.register(Service.Inventory, inventoryService);

    const theme = getTheme(env);

    return (
        <ServiceContext.Provider value={serviceLocator}>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                    <RouterProvider router={router}/>
                </GoogleOAuthProvider>
            </ThemeProvider>
        </ServiceContext.Provider>
    );
}

const getTheme = (env: string) => {
    switch (env) {
    case 'dev':
        return devTheme;
    case 'prod':
        return prodTheme;
    default:
        return localTheme;
    }
};
export default App;
