import React, {useState} from 'react';
import {AddCustomerDialog} from './addCustomerDialog';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Link} from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmAlert} from 'react-confirm-alert';
import {ICustomerService} from '../../service/customer/customerService';
import {Customer} from '../../model/customer/customer';

export interface CustomersComponentProps {
    customers: ServiceResponse<Customer[]>;
}

export const CustomersComponent = (props: CustomersComponentProps) => {
    const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState<boolean>(false);
    const [customerService] = useService<ICustomerService>(Service.Customer);
    const [addCustomerError, setAddCustomerError] = useState<string>();

    return (
        <div>
            <h1>Customers</h1>
            <h2>Admin</h2>
            <AddCustomerDialog
                open={openAddCustomerDialog}
                handleClose={() => {
                    setOpenAddCustomerDialog(false);
                }}
                error={addCustomerError}
                handleSubmit={async (data) => {
                    const result = await customerService?.addCustomer(data);

                    if (result?.hasError()) {
                        setAddCustomerError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <Button variant={'contained'} onClick={() => {
                setOpenAddCustomerDialog(true);
            }}>Add Customer</Button>

            <h2>Registered Customers</h2>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.customers.response.map((customer, index) => {
                            return <TableRow
                                key={customer.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">{customer.id}</TableCell>
                                <TableCell align="right">{customer.name}</TableCell>
                                <TableCell align="right">
                                    <Button variant={'contained'}><Link to={`/admin/customer/${customer.id}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>View</Link></Button>
                                    <Button variant={'contained'} color={'error'} onClick={() => {
                                        confirmAlert({
                                            title: 'Delete Customer',
                                            message: `Are you sure you want to delete customer ${customer.id}?`,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: async () => {
                                                        await customerService?.deleteCustomer(customer.id ?? '');
                                                        window.location.reload();
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onClick: () => {}
                                                }
                                            ]
                                        });
                                    }}>Delete</Button>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
