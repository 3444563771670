import {IHttpClient} from 'src/core/http';
import {ServiceResponse} from '../../model/serviceResponse';
import {Impact} from '../../model/impact/impact';

export interface IImpactService {
    getImpacts(): Promise<ServiceResponse<Impact[]>>;
}

export class ImpactService implements IImpactService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async getImpacts(): Promise<ServiceResponse<Impact[]>> {
        return (await this.httpClient.get<Impact[]>(`${this.apiUrl}/impact`, {})).data;
    }
}
