import React from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {ICustomerService} from '../../service/customer/customerService';
import {Customer} from '../../model/customer/customer';

export interface CustomerComponentProps {
    customer: ServiceResponse<Customer>;
}

export const CustomerComponent = (props: CustomerComponentProps) => {
    const [customerService] = useService<ICustomerService>(Service.Customer);
    const customer = props.customer.response;

    return (
        <div>
            <h1>Customer - {props.customer.response.name}</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(customer).map((key, index) => {
                            return <TableRow key={key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell align="right">{typeof customer[key] === 'object' ? customer[key].en_US : customer[key]}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
