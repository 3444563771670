import React, {useState} from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {IUserService} from '../../service/user/userService';
import {UserResponse} from '../../model/user/userResponse';
import {RequestInventoryItemDialog} from './requestInventoryItemDialog';
import {IInventoryService} from '../../service/inventory/inventoryService';
import {InventoryItem} from '../../model/inventory/inventoryItem';
import {confirmAlert} from 'react-confirm-alert';

export interface UserComponentProps {
    user: ServiceResponse<UserResponse>;
    env: string;
}

export const UserComponent = (props: UserComponentProps) => {
    const [userService] = useService<IUserService>(Service.User);
    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const [openRequestInventoryItemDialog, setOpenRequestInventoryItemDialog] = useState<boolean>(false);
    const [requestInventoryItemError, setRequestInventoryItemError] = useState<string>();
    const user = props.user.response;
    const renderRewardClaims = () => {
        const claims: any[] = [];

        for (const customerInventory of (user.customerInventories || [])) {
            for (let i = 0; i < (customerInventory.inventoryAllocations || []).length; i++) {
                const allocation = customerInventory.inventoryAllocations[i];

                const claim = user.user.claims?.[customerInventory.id ?? '']?.[i];
                const claimedAt = claim?.['claimedAt'];

                let participated = false;
                for (const item of (user.inventory || [])) {
                    if (item.customerInventoryId == customerInventory.id && item.customerInventoryAllocationIndex == i) {
                        participated = true;
                        break;
                    }
                }

                claims.push(<TableRow key={`${customerInventory.id}_${i}`} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell>{customerInventory.id}</TableCell>
                    <TableCell align="right">{i}</TableCell>
                    <TableCell align="right">{allocation.completedAt ? 'Completed' : allocation.active ? 'In Progress' : 'Not Started'}</TableCell>
                    <TableCell align="right">{participated ? 'Participated' : 'Not Participated'}</TableCell>
                    <TableCell align="right">{claimedAt ? `${new Date(claimedAt).toLocaleString()} - ${claim.reward}` : 'Not Claimed'}</TableCell>
                    <TableCell align="right">
                        {!claimedAt && participated && allocation.completedAt ? <Button variant={'contained'} onClick={async () => {
                            await userService?.claimReward(user.user.id, customerInventory.id ?? '', i);
                            window.location.reload();
                        }}>Claim</Button> : ''}
                    </TableCell>
                </TableRow>);
            }
        }

        return claims;
    };

    return (
        <div>
            <h1>User - {user.user.id}</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(user.user).map((key, index) => {
                            return <TableRow key={key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell align="right">{typeof user.user[key] === 'object' ? user.user[key].en_US : user.user[key]}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <h1>Reward Claims</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer Inventory Id</TableCell>
                            <TableCell align="right">Allocation Index</TableCell>
                            <TableCell align="right">Allocation State</TableCell>
                            <TableCell align="right">Participation Status</TableCell>
                            <TableCell align="right">Claim State</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderRewardClaims()}
                    </TableBody>
                </Table>
            </TableContainer>

            <h1>Inventory Items</h1>
            <RequestInventoryItemDialog
                open={openRequestInventoryItemDialog}
                handleClose={() => {
                    setOpenRequestInventoryItemDialog(false);
                }}
                error={requestInventoryItemError}
                handleSubmit={async (type, quantity) => {
                    const result = await inventoryService?.requestInventory(user.user.id, user.user.customerId, type, quantity);

                    if (result?.hasError()) {
                        setRequestInventoryItemError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <span  style={{width: '25%', display: 'flex', justifyContent: 'space-between', gap:'10px'}}>
                <Button variant={'contained'} onClick={async () => {
                    setOpenRequestInventoryItemDialog(true);
                }}>Request Inventory</Button>

                {props.env != 'prod' &&
                    <Button variant={'contained'} onClick={() => {
                        confirmAlert({
                            title: 'Delete Inventory',
                            message: 'Are you sure you want to delete all inventory items?',
                            buttons: [
                                {
                                    label: 'Yes',
                                    onClick: async () => {
                                        await inventoryService?.deleteAllInventoryItems(user.user.id, user.user.customerId);
                                        window.location.reload();
                                    }
                                },
                                {
                                    label: 'No',
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onClick: () => {}
                                }
                            ]
                        });
                    }}>Delete Inventory</Button>
                }
            </span>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">Inventory Ordinal</TableCell>
                            <TableCell align="right">CustomerId</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">CustomerInventoryId</TableCell>
                            <TableCell align="right">AllocationIndex</TableCell>
                            <TableCell align="right">Country</TableCell>
                            <TableCell align="right">DefinitionId</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">StatusDetail</TableCell>
                            <TableCell align="right">Targeted At</TableCell>
                            <TableCell align="right">Created At</TableCell>
                            <TableCell align="right">Updated At</TableCell>
                            <TableCell align="right">Matched At</TableCell>
                            <TableCell align="right">Fulfilled At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user.inventory?.map((item, index) => {
                            return <TableRow key={item.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell align="right">{item.inventoryOrdinal}</TableCell>
                                <TableCell align="right">{item.customerId}</TableCell>
                                <TableCell align="right">{item.type}</TableCell>
                                <TableCell align="right">{item.customerInventoryId}</TableCell>
                                <TableCell align="right">{item.customerInventoryAllocationIndex}</TableCell>
                                <TableCell align="right">{item.country}</TableCell>
                                <TableCell align="right">{item.inventoryItemDefinitionId}</TableCell>
                                <TableCell align="right">{item.status.en_US}</TableCell>
                                <TableCell align="right">{item.statusDetail.en_US}</TableCell>
                                <TableCell align="right">{item.targetedAt ? new Date(item.targetedAt).toLocaleString() : '--'}</TableCell>
                                <TableCell align="right">{item.createdAt ? new Date(item.createdAt).toLocaleString() : '--'}</TableCell>
                                <TableCell align="right">{item.updatedAt ? new Date(item.updatedAt).toLocaleString() : '--'}</TableCell>
                                <TableCell align="right">{item.matchedAt ? new Date(item.matchedAt).toLocaleString() : '--'}</TableCell>
                                <TableCell align="right">{item.fulfilledAt ? new Date(item.fulfilledAt).toLocaleString() : '--'}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
