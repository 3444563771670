import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {ImpactType} from '../../model/impact/impactType';
import 'react-datepicker/dist/react-datepicker.css';

export interface AddProjectDialogProps {
    error?: string;
    open: boolean;
    handleClose: () => void;
    handleSubmit: (type: ImpactType, quantity: number) => Promise<void>;
}

export const RequestInventoryItemDialog = (props: AddProjectDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;
    const [type, setType] = useState<ImpactType>(ImpactType.Trees);
    const [quantity, setQuantity] = useState<number>(1);

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Request Inventory</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel htmlFor="type">Type</InputLabel>
                        <Select id="type" defaultValue={ImpactType.Trees} onChange={(event) => {
                            setType(event.target.value as ImpactType);
                        }}>
                            <MenuItem key={ImpactType.Trees} value={ImpactType.Trees}>Tree</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="name" type={'text'} defaultValue={''} onChange={(event) => {
                            setQuantity(parseInt(event.target.value));
                        }} label='Quantity' />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleSubmit(type, quantity);
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
