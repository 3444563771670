import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {IProjectService} from '../../service/project/projectService';
import {ProjectComponent} from '../../component/project/projectComponent';
import {Service} from '../../service/serviceLocator';

interface ProjectProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    return {
        props: {
            env: ctx.req.env,
            projectId:ctx.req.query.projectId
        }
    };
};

const ProjectPage = (props: ProjectProps) => {
    const params = useParams();

    document.title = `Project - ${params.projectId} - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [projectService] = useService<IProjectService>(Service.Project);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [projectAndInventories, setProjectAndInventories] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await projectService?.getProject(params.projectId ?? '');
            setProjectAndInventories(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [projectService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <ProjectComponent projectAndInventories={projectAndInventories} />
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default ProjectPage;
