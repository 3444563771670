import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import {Copyright} from '@mui/icons-material';
import {capitalize, Typography} from '@mui/material';

interface IndexProps {
	env: string;
}

const Index = (props: IndexProps) => {
    document.title = `Real Impact Platform Admin (${capitalize(props.env)})`;

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <h1>Entities</h1>
                    <Typography><Link to={'/admin/partners'} style={{textDecoration: 'none'}}>Partners</Link></Typography>
                    <Typography><Link to={'/admin/customers'} style={{textDecoration: 'none'}}>Customers</Link></Typography>
                    <Typography><Link to={'/admin/users/search'} style={{textDecoration: 'none'}}>Users</Link></Typography>
                    <Typography><Link to={'/admin/inventory/itemDefinitions'} style={{textDecoration: 'none'}}>Inventory Item Definitions</Link></Typography>

                    <h1>Projects</h1>
                    <Typography><Link to={'/admin/projects'} style={{textDecoration: 'none'}}>Projects</Link></Typography>

                    <h1>Impact</h1>
                    <Link to={'/admin/impact'} style={{textDecoration: 'none'}}>Impact</Link>

                    <h1>Matchmaker</h1>
                    <Link to={'/admin/inventory/matchmaker'} style={{textDecoration: 'none'}}>Matchmaker</Link>
                </div>

                <Copyright />
            </Box>
        </Container>
    );
};

export default Index;
