export enum ServiceResponseCode {
    Ok,
    Error
}

export interface ServiceResponseServerModel {
    responseCode: ServiceResponseCode;
    response: any;
    errorCode: string;
}

export class ServiceResponse<T> {
    public responseCode: ServiceResponseCode;
    public response: T;
    public errorCode: string;

    constructor(responseCode: ServiceResponseCode, response: T, errorCode: string) {
        this.responseCode = responseCode;
        this.response = response;
        this.errorCode = errorCode;
    }

    hasError(): boolean {
        return this.responseCode === ServiceResponseCode.Error;
    }

    static ok<T>(response: T): ServiceResponse<T> {
        return new ServiceResponse(ServiceResponseCode.Ok, response, '');
    }

    static error<T>(errorCode: any): ServiceResponse<any> {
        return new ServiceResponse(ServiceResponseCode.Error, null, errorCode.toString());
    }

    static fromServerResponse<T>(data: ServiceResponseServerModel): ServiceResponse<T> {
        return new ServiceResponse(data.responseCode, data.response, data.errorCode);
    }
}
