import {IHttpClient} from 'src/core/http';
import {ServiceResponse} from '../../model/serviceResponse';
import {Partner} from '../../model/partner/partner';

export interface IPartnerService {
    listPartners(): Promise<ServiceResponse<Partner[]>>;

    addPartner(partnerData: any): Promise<ServiceResponse<string>>;

    getPartner(partnerId: string): Promise<ServiceResponse<Partner>>;

    deletePartner(partnerId: string): Promise<ServiceResponse<boolean>>;
}

export class PartnerService implements IPartnerService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async listPartners(): Promise<ServiceResponse<Partner[]>> {
        return (await this.httpClient.get<Partner[]>(`${this.apiUrl}/partner`, {})).data;
    }

    public async addPartner(partnerData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/partner`, partnerData)).data;
    }

    public async getPartner(partnerId: string): Promise<ServiceResponse<Partner>> {
        return (await this.httpClient.get<Partner>(`${this.apiUrl}/partner/${partnerId}`)).data;
    }

    public async deletePartner(partnerId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/partner/${partnerId}`)).data;
    }
}
