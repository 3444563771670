import {Button, capitalize, Grid, IconButton, styled, Toolbar, Typography} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuth, useUser} from 'src/hook/authHook';
import {AuthService, IAuthService} from 'src/service/auth/authService';
import {useService} from 'src/hook/serviceLocatorHook';
import {Link} from 'react-router-dom';
import {Service} from '../../service/serviceLocator';

export interface IAppBarWithOpenState extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 240;

export interface IAppBarProps extends IAppBarWithOpenState {
    env: string;
    version: string;
    handleDrawerOpen(): void;
}

export const AppBar = (props: IAppBarProps) => {
    const {open} = props;
    const [user]: any = useUser();
    const [isAuthenticated] = useAuth();
    const [authService] = useService<IAuthService>(Service.AuthService);
    const MaterialAppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<IAppBarWithOpenState>(({theme, open}) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    return (
        <MaterialAppBar position="fixed" open={open} color="secondary">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    sx={{mr: 2, ...(open && {display: 'none'})}}
                >
                    <MenuIcon />
                </IconButton>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant="h6" noWrap component="div">
                            <Link to="/admin" style={{textDecoration: 'none'}}>Real Impact Platform - Admin ({capitalize(props.env)}) - {props.version}</Link>
                        </Typography>
                    </Grid>
                    {isAuthenticated && user &&
                        <Grid item xs={3}>
                            <Typography noWrap component="div">
                                {user?.email}
                                <Button
                                    onClick={() => {
                                        if (authService) {
                                            authService.signOut();
                                        }
                                    }}>
                                    Sign out
                                </Button>
                            </Typography>
                        </Grid>
                    }
                </Grid>



            </Toolbar>
        </MaterialAppBar>
    );
};
