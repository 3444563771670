import React, {useState} from 'react';
import {AddProjectDialog} from './addProjectDialog';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Project} from '../../model/project/project';
import {Link} from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {confirmAlert} from 'react-confirm-alert';
import {IProjectService} from '../../service/project/projectService';
import {ImpactType} from '../../model/impact/impactType';
import {IPartnerService} from '../../service/partner/partnerService';
import {Partner} from '../../model/partner/partner';
import {InventoryItemDefinition} from '../../model/inventory/inventoryItemDefinition';
import {IInventoryService} from '../../service/inventory/inventoryService';

export interface ProjectsComponentProps {
    projects: ServiceResponse<Project[]>;
}

export const ProjectsComponent = (props: ProjectsComponentProps) => {
    const [openAddProjectDialog, setOpenAddProjectDialog] = useState<boolean>(false);
    const [projectService] = useService<IProjectService>(Service.Project);
    const [partnerService] = useService<IPartnerService>(Service.Partner);
    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const [partnerList, setPartnerList] = useState<Partner[]>([]);
    const [inventoryItemDefinitionList, setInventoryItemDefinitionList] = useState<InventoryItemDefinition[]>([]);
    const [addProjectError, setAddProjectError] = useState<string>();

    return (
        <div>
            <h1>Projects</h1>
            <h2>Admin</h2>
            <AddProjectDialog
                open={openAddProjectDialog}
                partners={partnerList}
                inventoryItemDefinitions={inventoryItemDefinitionList}
                handleClose={() => {
                    setOpenAddProjectDialog(false);
                }}
                error={addProjectError}
                handleSubmit={async (data) => {
                    if (!data.inventoryTotal) {
                        setAddProjectError('Inventory must be a number!');
                        return;
                    }

                    const result = await projectService?.addProject(data);

                    if (result?.hasError()) {
                        setAddProjectError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <Button variant={'contained'} onClick={async () => {
                if (partnerList.length == 0) {
                    const partners = await partnerService?.listPartners();
                    setPartnerList(partners?.response || []);
                }

                if (inventoryItemDefinitionList.length == 0) {
                    const inventoryItemDefinitions = await inventoryService?.listInventoryItemDefinitions();
                    setInventoryItemDefinitionList(inventoryItemDefinitions?.response || []);
                }

                setOpenAddProjectDialog(true);
            }}>Add Project</Button>

            <h2>Registered Projects</h2>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.projects.response.map((project, index) => {
                            return <TableRow
                                key={project.id}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">{project.id}</TableCell>
                                <TableCell align="right">{project.name.en_US}</TableCell>
                                <TableCell align="right">{ImpactType[project.type]}</TableCell>
                                <TableCell align="right">
                                    <Button variant={'contained'}><Link to={`/admin/project/${project.id}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>View</Link></Button>
                                    <Button variant={'contained'} color={'error'} onClick={() => {
                                        confirmAlert({
                                            title: 'Delete Project',
                                            message: `Are you sure you want to delete project ${project.id}?`,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: async () => {
                                                        await projectService?.deleteProject(project.id ?? '');
                                                        window.location.reload();
                                                    }
                                                },
                                                {
                                                    label: 'No',
                                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                    onClick: () => {}
                                                }
                                            ]
                                        });
                                    }}>Delete</Button>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
