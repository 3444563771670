import {
    Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {LocalizedString} from '../../model/localizedString';
import {ImpactType} from '../../model/impact/impactType';
import {Partner} from '../../model/partner/partner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {countryCodes} from '../../model/inventory/countryCodes';
import {InventoryItemDefinition} from '../../model/inventory/inventoryItemDefinition';
import DeleteIcon from '@mui/icons-material/Delete';

export interface AddProjectDialogProps {
    error?: string;
    open: boolean;
    partners: Partner[];
    inventoryItemDefinitions: InventoryItemDefinition[];
    handleClose: () => void;
    handleSubmit: (data: any) => Promise<void>;
}

export const AddProjectDialog = (props: AddProjectDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;
    const [name, setName] = useState<LocalizedString>();
    const [description, setDescription] = useState<LocalizedString>();
    const [partnerId, setPartnerId] = useState<string>();
    const [type, setType] = useState<ImpactType>();
    const [selectedInventoryItemDefinitionId, setSelectedInventoryItemDefinitionId] = useState<string>('');
    const [inventoryDistribution, setInventoryDistribution] = useState<number>();
    const [inventoryItemDefinitionIds, setInventoryItemDefinitionIds] = useState<any>({});
    const [imageUrl, setImageUrl] = useState<LocalizedString>();
    const [projectUrl, setProjectUrl] = useState<LocalizedString>();
    const [inventoryTotal, setInventoryTotal] = useState<number>();
    const [continent, setContinent] = useState<string>();
    const [country, setCountry] = useState<string>('CA');
    const [state, setState] = useState<string>();
    const [latitude, setLatitude] = useState<string>();
    const [longitude, setLongitude] = useState<string>();
    const [startAt, setStartAt] = useState<number>(new Date().getTime());

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Add Project</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="name" type={'text'} defaultValue={''} onChange={(event) => {
                            setName(new LocalizedString(event.target.value));
                        }} label='Name'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="description" type={'text'} defaultValue={''} onChange={(event) => {
                            setName(new LocalizedString(event.target.value));
                        }} label='Description'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="partner">Partner</InputLabel>
                        <Select id="partner" defaultValue={props.partners.length > 0 ? props.partners[0].id : ''} onChange={(event) => {
                            setType(props.partners[event.target.value].type);
                            setPartnerId(props.partners[event.target.value].id);
                        }}>
                            {props.partners.map((p, i) => {
                                return <MenuItem key={p.id} value={i}>{p.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="inventoryItemDefinition">Inventory Item Definition</InputLabel>
                        <Select id="inventoryItemDefinition" defaultValue={props.inventoryItemDefinitions.length > 0 ? props.inventoryItemDefinitions[0].id : ''} onChange={(event) => {
                            const iid = props.inventoryItemDefinitions[event.target.value].id;

                            setSelectedInventoryItemDefinitionId(iid);
                        }}>
                            {props.inventoryItemDefinitions.map((p, i) => {
                                return <MenuItem key={p.id} value={i}>{p.name}</MenuItem>;
                            })}
                        </Select>
                        <TextField id="distribution" type={'number'} defaultValue={''} onChange={(event) => {
                            setInventoryDistribution(parseFloat(event.target.value));
                        }} label='Distribution'/>
                        <Button variant={'outlined'} onClick={() => {
                            if (!inventoryItemDefinitionIds[selectedInventoryItemDefinitionId]) {
                                const newIds = {};

                                for (const oldId of Object.keys(inventoryItemDefinitionIds)) {
                                    newIds[oldId] = inventoryItemDefinitionIds[oldId];
                                }
                                newIds[selectedInventoryItemDefinitionId] = inventoryDistribution;

                                setInventoryItemDefinitionIds(newIds);
                            }
                        }}>Add Distribution</Button>
                        {Object.keys(inventoryItemDefinitionIds).map((iid, index) => {
                            return <Chip
                                label={`${props.inventoryItemDefinitions.find(i => i.id == iid)?.name}:${inventoryItemDefinitionIds[iid] * 100}%`}
                                onDelete={() => {
                                    const updatedIds = {};

                                    for (const oldId of Object.keys(inventoryItemDefinitionIds)) {
                                        if (oldId !== iid) {
                                            updatedIds[oldId] = inventoryItemDefinitionIds[oldId];
                                        }
                                    }

                                    setInventoryItemDefinitionIds(updatedIds);
                                }}
                                deleteIcon={<DeleteIcon />}
                                variant="outlined"
                            />;
                        })}
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="imageUrl" type={'text'} defaultValue={''} onChange={(event) => {
                            setImageUrl(new LocalizedString(event.target.value));
                        }} label='Image URL' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="projectUrl" type={'text'} defaultValue={''} onChange={(event) => {
                            setProjectUrl(new LocalizedString(event.target.value));
                        }} label='Project URL' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="inventoryTotal" type={'text'} defaultValue={''} onChange={(event) => {
                            setInventoryTotal(parseInt(event.target.value));
                        }} label='Inventory Total' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="continent" type={'text'} defaultValue={''} onChange={(event) => {
                            setContinent(event.target.value);
                        }} label='Continent' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <Select id="country" defaultValue={'CA'} onChange={(event) => {
                            setCountry(event.target.value);
                        }}>
                            {Object.keys(countryCodes).map((c) => {
                                return <MenuItem key={c} value={c}>{countryCodes[c]}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="state" type={'text'} defaultValue={''} onChange={(event) => {
                            setState(event.target.value);
                        }} label='State' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="latitude" type={'text'} defaultValue={''} onChange={(event) => {
                            setLatitude(event.target.value);
                        }} label='Latitude' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="longitude" type={'text'} defaultValue={''} onChange={(event) => {
                            setLongitude(event.target.value);
                        }} label='Longitude' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        Start At <DatePicker showTimeSelect selected={new Date(startAt)} onChange={(date) => setStartAt(date?.getTime() || 0)} />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleSubmit({name, description, type, partnerId, inventoryItemDefinitionIds, imageUrl, projectUrl, inventoryTotal, location: {
                        continent, country, state, latitude, longitude
                    }, startAt});
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
