import {IHttpClient} from 'src/core/http';
import {ServiceResponse} from '../../model/serviceResponse';
import {UserResponse} from '../../model/user/userResponse';

export interface IUserService {
    getUser(userId: string, isClientUserId: boolean): Promise<ServiceResponse<UserResponse>>;

    deleteUser(userId: string): Promise<ServiceResponse<boolean>>;

    claimReward(userId: string, customerInventoryId: string, customerInventoryAllocationIndex: number): Promise<ServiceResponse<string>>;
}

export class UserService implements IUserService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async getUser(userId: string, isClientUserId: boolean): Promise<ServiceResponse<UserResponse>> {
        return (await this.httpClient.get<UserResponse>(`${this.apiUrl}/user/${userId}?isClientUserId=${isClientUserId}`)).data;
    }

    public async deleteUser(userId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/user/${userId}`)).data;
    }

    public async claimReward(userId: string, customerInventoryId: string, customerInventoryAllocationIndex: number): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/user/${userId}/claimReward/customerInventory/${customerInventoryId}/allocationIndex/${customerInventoryAllocationIndex}`, {})).data;
    }
}
