import React from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Partner} from '../../model/partner/partner';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {IPartnerService} from '../../service/partner/partnerService';

export interface PartnerComponentProps {
    partner: ServiceResponse<Partner>;
}

export const PartnerComponent = (props: PartnerComponentProps) => {
    const [partnerService] = useService<IPartnerService>(Service.Partner);
    const partner = props.partner.response;

    return (
        <div>
            <h1>Partner - {props.partner.response.name}</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(partner).map((key, index) => {
                            return <TableRow key={key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell align="right">{typeof partner[key] === 'object' ? partner[key].en_US : partner[key]}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
