import React from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {IInventoryService} from '../../service/inventory/inventoryService';
import {InventoryItemDefinition} from '../../model/inventory/inventoryItemDefinition';

export interface InventoryItemDefinitionComponentProps {
    definition: ServiceResponse<InventoryItemDefinition>;
}

export const InventoryItemDefinitionComponent = (props: InventoryItemDefinitionComponentProps) => {
    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const definition = props.definition.response;

    return (
        <div>
            <h1>Inventory Item Definition - {props.definition.response.commonName.en_US}</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(definition).map((key, index) => {
                            return <TableRow key={key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell align="right">{typeof definition[key] === 'object' ? definition[key].en_US : definition[key]}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
