import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {MatchmakerComponent} from '../../component/inventory/matchmakerComponent';
import {Service} from '../../service/serviceLocator';
import {IInventoryService} from '../../service/inventory/inventoryService';
import {MatchmakerHistory} from '../../model/inventory/matchmakerHistory';
import {ServiceResponse} from '../../model/serviceResponse';

interface MatchmakerProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    return {
        props: {
            env: ctx.req.env,
            partnerId:ctx.req.query.partnerId
        }
    };
};

const MatchmakerPage = (props: MatchmakerProps) => {
    const params = useParams();

    document.title = `Matchmaker - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [matchmakerHistory, setMatchmakerHistory] = useState<ServiceResponse<MatchmakerHistory[]>>(ServiceResponse.error('NotFound'));
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await inventoryService?.getMatchmakerHistory() ?? ServiceResponse.error('NotFound');
            setMatchmakerHistory(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [inventoryService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <MatchmakerComponent matchmakerHistory={matchmakerHistory} />
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default MatchmakerPage;
