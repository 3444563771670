import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {ImpactType} from '../../model/impact/impactType';
import {LocalizedString} from '../../model/localizedString';

export interface AddInventoryItemDefinitionDialogProps {
    error?: string;
    open: boolean;
    handleClose: () => void;
    handleSubmit: (data: any) => Promise<void>;
}

export const AddInventoryItemDefinitionDialog = (props: AddInventoryItemDefinitionDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;
    const [type, setType] = useState<ImpactType>(ImpactType.Trees);
    const [name, setName] = useState<string>();
    const [commonName, setCommonName] = useState<LocalizedString>();
    const [description, setDescription] = useState<LocalizedString>();
    const [imageCdnPath, setImageCdnPath] = useState<string>();
    const [imageDetailCdnPath, setImageDetailCdnPath] = useState<string>();

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Add Inventory Item Definition</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel htmlFor="role">Role</InputLabel>
                        <Select id="role" defaultValue={ImpactType.Trees} onChange={(event) => {
                            setType(event.target.value as ImpactType);
                        }}>
                            <MenuItem key={ImpactType.Trees} value={ImpactType.Trees}>Tree</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="name" type={'text'} defaultValue={''} onChange={(event) => {
                            setName(event.target.value);
                        }} label='Name'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="commonName" type={'text'} defaultValue={''} onChange={(event) => {
                            setCommonName(new LocalizedString(event.target.value));
                        }} label='Common Name'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="description" type={'text'} defaultValue={''} onChange={(event) => {
                            setDescription(new LocalizedString(event.target.value));
                        }} label='Description' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="imageCdnPath" type={'text'} defaultValue={''} onChange={(event) => {
                            setImageCdnPath(event.target.value);
                        }} label='Image CDN Path'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="imageDetailCdnPath" type={'text'} defaultValue={''} onChange={(event) => {
                            setImageCdnPath(event.target.value);
                        }} label='Image Detail CDN Path'/>
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleSubmit({type, name, commonName, description, imageCdnPath, imageDetailCdnPath});
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
