import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {ServiceResponse} from '../../model/serviceResponse';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Impact} from '../../model/impact/impact';
import {ImpactStat} from '../../model/impact/impactStat';
import {ImpactType} from '../../model/impact/impactType';

export interface ImpactComponentProps {
    impacts: ServiceResponse<Impact[]>;
}

export const ImpactComponent = (props: ImpactComponentProps) => {
    return (
        <div>
            <h1>Impact</h1>

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">CustomerId</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Stat</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.impacts.response.map((impact, index) => {
                            return <TableRow key={impact.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{impact.id}</TableCell>
                                <TableCell align="right">{impact.customerId}</TableCell>
                                <TableCell align="right">{ImpactType[impact.type]}</TableCell>
                                <TableCell align="right">{ImpactStat[impact.stat]}</TableCell>
                                <TableCell align="right">{impact.value}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
