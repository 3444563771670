import * as React from 'react';
import {useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {Button} from '@mui/material';


interface UserSearchPageProps {
    env: string;
}

const UserSearchPage = (props: UserSearchPageProps) => {
    const [userId, setUserId] = useState<string>();

    const openInNewTabUserId = () => {
        window.open(`/admin/user/${userId}`, '_blank', 'noopener,noreferrer');
    };

    const openInNewTabClientUserId = () => {
        window.open(`/admin/user/${userId}?isClientUserId=true`, '_blank', 'noopener,noreferrer');
    };

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >

                <div key={'FindUser'}>
                    <h1>Find User</h1>
                    <form onSubmit={openInNewTabUserId}>
                        <label>ID: <input type="text" name="userId" onChange={(evt) => setUserId(evt.target.value)}/> </label>
                        <Button variant="contained" type="submit">Find User By Id</Button>
                    </form>
                    <br /><br />
                    <form onSubmit={openInNewTabClientUserId}>
                        <label>ID: <input type="text" name="userId" onChange={(evt) => setUserId(evt.target.value)}/> </label>
                        <Button variant="contained" type="submit">Find User By Client User Id</Button>
                    </form>
                </div>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default UserSearchPage;
