import React, {useState} from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {MatchmakerHistory} from '../../model/inventory/matchmakerHistory';
import {IInventoryService} from '../../service/inventory/inventoryService';
import {ImpactType} from '../../model/impact/impactType';

export interface MatchmakerComponentProps {
    matchmakerHistory: ServiceResponse<MatchmakerHistory[]>;
}

export const MatchmakerComponent = (props: MatchmakerComponentProps) => {
    const [inventoryService] = useService<IInventoryService>(Service.Inventory);
    const [showLoading, setShowLoading] = useState<boolean>();
    const history = props.matchmakerHistory.response;

    return (
        <div>
            <h1>Matchmaker</h1>
            <Button variant={'contained'} onClick={async () => {
                setShowLoading(true);
                await inventoryService?.matchmake();
                window.location.reload();
            }}>Run Matchmaker</Button>
            {showLoading ? <div key={'transfer_loading'}>
                <CircularProgress /> Loading...
            </div> : ''}

            <h1>Matchmaker History</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="right">CustomerId</TableCell>
                            <TableCell align="right">Impact Type</TableCell>
                            <TableCell align="right">Items Evaluated</TableCell>
                            <TableCell align="right">Items Matched</TableCell>
                            <TableCell align="right">Started At</TableCell>
                            <TableCell align="right">Completed At</TableCell>
                            <TableCell align="right">Result</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((historyItem, index) => {
                            return <TableRow key={historyItem.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{historyItem.id}</TableCell>
                                <TableCell align="right">{historyItem.customerId}</TableCell>
                                <TableCell align="right">{ImpactType[historyItem.type]}</TableCell>
                                <TableCell align="right">{historyItem.itemsEvaluated}</TableCell>
                                <TableCell align="right">{historyItem.itemsMatched}</TableCell>
                                <TableCell align="right">{new Date(historyItem.runStartedAt).toLocaleString()}</TableCell>
                                <TableCell align="right">{new Date(historyItem.runCompletedAt).toLocaleString()}</TableCell>
                                <TableCell align="right">{historyItem.result}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
