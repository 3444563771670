export class LocalizedString {
    en_US: string;

    constructor(en_US: string) {
        this.en_US = en_US;
    }

    public toString(): string {
        return this.en_US;
    }
}
