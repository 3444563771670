import {ServiceResponse} from '../../model/serviceResponse';

export interface IHttpResponse<T> {
    hasError: boolean,
    data: ServiceResponse<T>
}

export type HttpHeader = { [key: string]: number | string };
export const AUTHORIZATION_HEADER = 'Authorization';

