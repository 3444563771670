import React, {useState} from 'react';
import {useService} from '../../hook/serviceLocatorHook';
import {Service} from '../../service/serviceLocator';
import {ServiceResponse} from '../../model/serviceResponse';
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {IProjectService} from '../../service/project/projectService';
import {ICustomerService} from '../../service/customer/customerService';
import {Customer} from '../../model/customer/customer';
import {AllocateCustomerInventoryDialog} from './allocateCustomerInventoryDialog';
import {CustomerInventory} from '../../model/inventory/customerInventory';
import {ProjectAndCustomerInventories} from '../../model/project/projectAndCustomerInventories';
import {Link} from 'react-router-dom';
import {InventoryAllocation} from '../../model/inventory/inventoryAllocation';

export interface ProjectComponentProps {
    projectAndInventories: ServiceResponse<ProjectAndCustomerInventories>;
}

export const ProjectComponent = (props: ProjectComponentProps) => {
    const [openAllocateCustomerInventoryDialog, setOpenAllocateCustomerInventoryDialog] = useState<boolean>(false);
    const [projectService] = useService<IProjectService>(Service.Project);
    const [customerService] = useService<ICustomerService>(Service.Customer);
    const [customerList, setCustomerList] = useState<Customer[]>([]);
    const [allocateInventoryError, setAllocateInventoryError] = useState<string>();
    const projectAndInventories = props.projectAndInventories.response;

    const uploadImage = (event) => {
        const reader = new FileReader();

        let fileLoaded = (e) => {
            handleImportVersion(e.target.result, event.target.files[0].name);
        };

        fileLoaded = fileLoaded.bind(this);
        reader.onload = fileLoaded;
        reader.readAsDataURL(event.target.files[0]); // read the file
    };

    const handleImportVersion = async (data: any, fileName: string) => {
        await projectService?.addImage(projectAndInventories.project.id, {fileName, data});
        window.location.reload();
    };

    return (
        <div>
            <h1>Project - {projectAndInventories.project.name.en_US}</h1>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(projectAndInventories.project).map((key, index) => {
                            let output = '';

                            if (!projectAndInventories.project[key]) {
                                return;
                            }

                            if (typeof projectAndInventories.project[key] === 'object') {
                                for (const subKey of Object.keys(projectAndInventories.project[key])) {
                                    output += `${subKey} - ${projectAndInventories.project[key][subKey]},`;
                                }
                                output = output.substring(0, output.length - 1);
                            } else {
                                output = projectAndInventories.project[key];
                            }

                            return <TableRow key={key} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell align="right">{output}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <h1>Images</h1>
            Add Image <input type="file" multiple={false} accept="image/*" onChange={uploadImage} />

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ImageId</TableCell>
                            <TableCell align="right">URL</TableCell>
                            <TableCell align="right">Uploaded At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(projectAndInventories.project.images || {}).map(imageId => {
                            return <TableRow key={imageId} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row">{imageId}</TableCell>
                                <TableCell align="right"><a href={projectAndInventories.project.images[imageId].url}>{projectAndInventories.project.images[imageId].originalFilename}</a></TableCell>
                                <TableCell align="right">{new Date(projectAndInventories.project.images[imageId].createdAt).toLocaleString()}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <h1>Inventory Allocation</h1>
            <AllocateCustomerInventoryDialog
                open={openAllocateCustomerInventoryDialog}
                customers={customerList}
                availableInventory={projectAndInventories.project.inventoryRemaining}
                handleClose={() => {
                    setOpenAllocateCustomerInventoryDialog(false);
                }}
                error={allocateInventoryError}
                handleSubmit={async (data) => {
                    const result = await projectService?.allocateCustomerInventory(projectAndInventories.project.id, data);

                    if (result?.hasError()) {
                        setAllocateInventoryError(result.errorCode);
                    } else {
                        window.location.reload();
                    }
                }}
            />
            <Button variant={'contained'} onClick={async () => {
                if (customerList.length == 0) {
                    const customers = await customerService?.listCustomers();
                    setCustomerList(customers?.response || []);
                }

                setOpenAllocateCustomerInventoryDialog(true);
            }}>Allocate Inventory</Button>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Id</TableCell>
                            <TableCell component="th" align="right">State</TableCell>
                            <TableCell component="th" align="right">Created At</TableCell>
                            <TableCell component="th" align="right">Completed At</TableCell>
                            <TableCell component="th" align="right">Customer</TableCell>
                            <TableCell component="th" align="right">Progress</TableCell>
                            <TableCell component="th" align="right">Allocations</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(projectAndInventories.customerInventory).map((inventoryKey, inventoryIndex) => {
                            const customerInventory: CustomerInventory = projectAndInventories.customerInventory[inventoryKey];

                            return <TableRow>
                                <TableCell>{customerInventory.id}</TableCell>
                                <TableCell>{customerInventory.active ? 'In Progress' : 'Inactive'}</TableCell>
                                <TableCell>{new Date(customerInventory.createdAt).toLocaleString()}</TableCell>
                                <TableCell>{customerInventory.completedAt > 0 ? new Date(customerInventory.completedAt).toLocaleString() : 'Incomplete'}</TableCell>
                                <TableCell><Button variant={'contained'}>
                                    <Link to={`/admin/customer/${customerInventory.customerId}`} style={{textDecoration: 'none', color: '#FFFFFF'}}>View</Link>
                                </Button></TableCell>
                                <TableCell>{customerInventory.inventoryTotal - customerInventory.inventoryRemaining}/{customerInventory.inventoryTotal}</TableCell>
                                <TableCell>
                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component="th">Name</TableCell>
                                                    <TableCell component="th" align="right">State</TableCell>
                                                    <TableCell component="th" align="right">Started At</TableCell>
                                                    <TableCell component="th" align="right">Completed At</TableCell>
                                                    <TableCell component="th" align="right">Progress</TableCell>
                                                    <TableCell component="th" align="right">Completion Reward</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(customerInventory.inventoryAllocations).map((allocationKey, allocationIndex) => {
                                                    const allocation: InventoryAllocation = customerInventory.inventoryAllocations[allocationKey];

                                                    return <TableRow>
                                                        <TableCell>{allocation.name.en_US}</TableCell>
                                                        <TableCell align="right">{allocation.active ? 'In Progress' : 'Inactive'}</TableCell>
                                                        <TableCell>{allocation.startedAt > 0 ? new Date(allocation.startedAt).toLocaleString() : 'Not Started'}</TableCell>
                                                        <TableCell>{allocation.completedAt > 0 ? new Date(allocation.completedAt).toLocaleString() : 'Incomplete'}</TableCell>
                                                        <TableCell align="right">{allocation.inventoryTotal - allocation.inventoryRemaining}/{allocation.inventoryTotal}</TableCell>
                                                        <TableCell align="right">{allocation.completionReward}</TableCell>
                                                    </TableRow>;
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
