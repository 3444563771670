import {IHttpClient} from 'src/core/http';
import {Project} from '../../model/project/project';
import {ServiceResponse} from '../../model/serviceResponse';
import {ProjectAndCustomerInventories} from '../../model/project/projectAndCustomerInventories';

export interface IProjectService {
    listProjects(): Promise<ServiceResponse<Project[]>>;

    addProject(projectData: any): Promise<ServiceResponse<string>>;

    addImage(projectId: string, imageData: any): Promise<ServiceResponse<string>>;

    getProject(projectId: string): Promise<ServiceResponse<ProjectAndCustomerInventories>>;

    deleteProject(projectId: string): Promise<ServiceResponse<boolean>>;

    allocateCustomerInventory(projectId: string, allocationData: any): Promise<ServiceResponse<string>>;
}

export class ProjectService implements IProjectService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async listProjects(): Promise<ServiceResponse<Project[]>> {
        return (await this.httpClient.get<Project[]>(`${this.apiUrl}/project`, {})).data;
    }

    public async addProject(projectData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/project`, projectData)).data;
    }

    public async addImage(projectId: string, imageData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/project/${projectId}/uploadImage`, imageData)).data;
    }

    public async getProject(projectId: string): Promise<ServiceResponse<ProjectAndCustomerInventories>> {
        return (await this.httpClient.get<ProjectAndCustomerInventories>(`${this.apiUrl}/project/${projectId}`)).data;
    }

    public async deleteProject(projectId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/project/${projectId}`)).data;
    }

    public async allocateCustomerInventory(projectId: string, allocationData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/project/${projectId}/allocateCustomerInventory`, allocationData)).data;
    }
}
