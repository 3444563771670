import {IHttpClient} from 'src/core/http';
import {ServiceResponse} from '../../model/serviceResponse';
import {Customer} from '../../model/customer/customer';

export interface ICustomerService {
    listCustomers(): Promise<ServiceResponse<Customer[]>>;

    addCustomer(customerData: any): Promise<ServiceResponse<string>>;

    getCustomer(customerId: string): Promise<ServiceResponse<Customer>>;

    deleteCustomer(customerId: string): Promise<ServiceResponse<boolean>>;
}

export class CustomerService implements ICustomerService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async listCustomers(): Promise<ServiceResponse<Customer[]>> {
        return (await this.httpClient.get<Customer[]>(`${this.apiUrl}/customer`, {})).data;
    }

    public async addCustomer(customerData: any): Promise<ServiceResponse<string>> {
        return (await this.httpClient.post<string>(`${this.apiUrl}/customer`, customerData)).data;
    }

    public async getCustomer(customerId: string): Promise<ServiceResponse<Customer>> {
        return (await this.httpClient.get<Customer>(`${this.apiUrl}/customer/${customerId}`)).data;
    }

    public async deleteCustomer(customerId: string): Promise<ServiceResponse<boolean>> {
        return (await this.httpClient.delete<boolean>(`${this.apiUrl}/customer/${customerId}`)).data;
    }
}
