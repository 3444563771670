import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, InputLabel, MenuItem,
    Select, TextareaAutosize,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {LocalizedString} from '../../model/localizedString';

export interface AddCustomerDialogProps {
    error?: string;
    open: boolean;
    handleClose: () => void;
    handleSubmit: (data: any) => Promise<void>;
}

export const AddCustomerDialog = (props: AddCustomerDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;
    const [name, setName] = useState<string>();
    const [logoUrl, setLogoUrl] = useState<string>();
    const [bio, setBio] = useState<LocalizedString>();
    const [urlTitle, setUrlTitle] = useState<LocalizedString>();
    const [url, setUrl] = useState<LocalizedString>();

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Add Customer</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="name" type={'text'} defaultValue={''} onChange={(event) => {
                            setName(event.target.value);
                        }} label='Name'/>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="logoUrl" type={'text'} defaultValue={''} onChange={(event) => {
                            setLogoUrl(event.target.value);
                        }} label='Logo URL' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <InputLabel htmlFor="bio">Bio</InputLabel>
                        <TextareaAutosize id="bio" minRows={8} defaultValue={''} onChange={(event) => {
                            setBio(new LocalizedString(event.target.value));
                        }} />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="urlTitle" type={'text'} defaultValue={''} onChange={(event) => {
                            setUrlTitle(new LocalizedString(event.target.value));
                        }} label='URL Title' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="url" type={'text'} defaultValue={''} onChange={(event) => {
                            setUrl(new LocalizedString(event.target.value));
                        }} label='URL' />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {
                    handleSubmit({name, logoUrl, bio, urlTitle, url});
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};
