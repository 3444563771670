import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {useParams, useSearchParams} from 'react-router-dom';
import {IUserService} from '../../service/user/userService';
import {Service} from '../../service/serviceLocator';
import {UserComponent} from '../../component/user/userComponent';

interface UserProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    alert(ctx.req.query.isClientUserId);
    return {
        props: {
            env: ctx.req.env,
            userId: ctx.req.query.userId,
            isClientUserId: ctx.req.query.isClientUserId
        }
    };
};

const UserPage = (props: UserProps) => {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    document.title = `User - ${params.userId} - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [userService] = useService<IUserService>(Service.User);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [user, setUser] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await userService?.getUser(params.userId ?? '', searchParams.get('isClientUserId') === 'true');
            setUser(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [userService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'left',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <UserComponent user={user} env={props.env}/>
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default UserPage;
