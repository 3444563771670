import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {IPartnerService} from '../../service/partner/partnerService';
import {PartnerComponent} from '../../component/partner/partnerComponent';
import {Service} from '../../service/serviceLocator';

interface PartnerProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    return {
        props: {
            env: ctx.req.env,
            partnerId:ctx.req.query.partnerId
        }
    };
};

const PartnerPage = (props: PartnerProps) => {
    const params = useParams();

    document.title = `Partner - ${params.partnerId} - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [partnerService] = useService<IPartnerService>(Service.Partner);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [partner, setPartner] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await partnerService?.getPartner(params.partnerId ?? '');
            setPartner(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [partnerService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <PartnerComponent partner={partner} />
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default PartnerPage;
