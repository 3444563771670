import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {capitalize, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {Service} from '../../service/serviceLocator';
import {ICustomerService} from '../../service/customer/customerService';
import {CustomerComponent} from '../../component/customer/customerComponent';

interface CustomerProps {
    env: string;
}

export const getServerSideProps = async function (ctx) {
    return {
        props: {
            env: ctx.req.env,
            customerId:ctx.req.query.customerId
        }
    };
};

const CustomerPage = (props: CustomerProps) => {
    const params = useParams();

    document.title = `Customer - ${params.customerId} - Real Impact Platform Admin (${capitalize(props.env)})`;

    const [customerService] = useService<ICustomerService>(Service.Customer);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [customer, setCustomer] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await customerService?.getCustomer(params.customerId ?? '');
            setCustomer(result);
            setIsFetching(false);
        };
        fetchData().catch((error) => {
            // issue was there.
        });
    }, [customerService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching ?
                        (<div>
                            <Typography>Fetching data...</Typography>
                        </div>
                        )
                        :
                        (
                            <div>
                                <CustomerComponent customer={customer} />
                            </div>
                        )}
                </div>

                <Copyright/>
            </Box>
        </Container>
    );
};

export default CustomerPage;
